.template {
  background-color: var(--white);
  padding: 25px 20px;
  margin: 0 auto;
  width: 100%;
  min-height: 950px;
}

.cta_button button {
  font-weight: 500 !important;
}/*# sourceMappingURL=style.css.map */