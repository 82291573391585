:root {
  --text-color: #57584e;
  --hover-blue: #197cd7;
  --pressed-blue: #2147a8;
  --disabled-blue: rgba(25, 124, 215, 0.7);

  --deeper-blue: #171832;
  --sky-blue: #26abc7;
  --white: #fff;
  --light-font: #8a92a6;
  --secondary-grey: #f7f7f9;
  --secondary-grey-text: #6f727a;
  --danger: #f32d2d;
  --success: #55bd68;
  --toastify-color-error: blue;
  --carton: #f7f7f9;
  --ash: #6c6e7f;
  --primary-color: #45079a;
  --light-purple: #f7f6fd;
  --lighter-purple: rgba(235, 233, 249, 0.6);

  --primary-color-black: #232325;
}

*,
:before,
:after {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  // overflow-x: hidden !important;
}

//font-face inter --- starts

@font-face {
  font-family: Inter-Extra-Light;
  src: url("../fonts/inter/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: Inter-Light;
  src: url("../fonts/inter/Inter-Light.ttf");
}

@font-face {
  font-family: Inter-Regular;
  src: url("../fonts/inter/Inter-Regular.ttf");
}

@font-face {
  font-family: Inter-Medium;
  src: url("../fonts/inter/Inter-Medium.ttf");
}

//font-face inter --- ends

//body

body {
  width: 100%;
  height: auto;
  background-color: var(--light-purple);
}

*,
::before,
::after {
  padding: 0px;
  margin: 0px;
  font-family: Inter-Regular;
}

a,
Link {
  color: unset;
  cursor: pointer;
  text-decoration: none;
}

//text anchor tags --- starts
p {
  font-size: 14.75px;
  line-height: 22.5px;
}

//text anchor tags --- ends

button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 8px;
  color: var(--white);
  font-family: Inter-Regular;
}

table {
  tr th,
  tr td {
    color: var(--primary-color-black);

    > * {
      color: var(--primary-color-black);
    }
  }
}

table {
  width: 100%;
  padding: 30px;
  margin: 0px 0px;
  border-collapse: collapse;
  // table-layout: fixed;

  tbody {
    tr {
      td {
        padding: 15px 10px;

        word-break: break-all;
        // background-color: pink;
        h3 {
          font-size: 13px;
          margin-bottom: 7px;
          color: var(--primary-color-black);
        }

        p {
          font-size: 13px;
          color: var(--primary-color-black);
        }
      }

      // border-bottom: 1px solid #e0e2e7;
    }
  }

  tbody tr:last-child {
    border-bottom: unset;
  }

  thead {
    background-color: var(--light-purple);
    // background-color: var(--secondary-grey);
    // border-radius: 12px 0px 12px 0px;

    th {
      text-align: left;
      font-size: 13px;
      color: var(--secondary-grey-text) !important;
    }
  }

  th,
  td {
    padding: 10px;
    font-size: 13px;
    min-width: 100px;
  }

  tbody tr:nth-child(even) {
    background-color: var(--light-purple);
  }
}

.action_td {
  margin-left: 5px;

  .action_td_cage {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 20px;
    // max-width: 90px;
    color: #e0e2e7 !important;

    > * {
      margin: 0px;
    }
  }

  .action_add {
    * {
      color: var(--primary-color);
      font-size: 16px;
    }
  }

  .action_edit {
    // margin-right: 12.5px;
    cursor: pointer;

    * {
      color: var(--primary-color);
      font-size: 16px;
    }
  }
}

.action_delete {
  // margin-left: 12.5px;
  cursor: pointer;

  * {
    color: #f32d2d !important;
  }
}

.action_restart {
  // margin-left: 12.5px;
  cursor: pointer;

  * {
    color: #249f5d !important;
  }
}

.status_button {
  button {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 8px 20px;
    border-radius: 10px;
    // color: var(--white);
    font-family: Inter-Regular;
  }
}

form {
  width: 100%;
  height: auto;

  button {
    width: 100%;
    margin: 20px auto;
    display: block;
    text-align: center;
    border: none;
    outline: none;
    font-size: 13px;
    color: var(--primary-color);
    background-color: var(--white);
    padding: 13px 11px;
    border-radius: 6px;
    cursor: pointer;
  }

  .login-button {
    margin: 35px 0px 20px 0px;
  }

  button:disabled {
    background-color: var(--primary-color);
    color: #ffffff;
    font-weight: 600;
  }

  input,
  textarea,
  select {
    padding: 12px;
    font-size: 13px;
    border-radius: 6px;
    outline: none;
    border: none;
    position: relative;
    width: 100%;
    border: 1px solid #eeeeee;
  }

  // select::-ms-expand {
  //   display: none;
  // }

  // select {
  //   background-color: #ffffff;
  //   appearance: none;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  // }

  input:focus,
  textarea:focus,
  select:focus {
    border: 1px solid var(--primary-color);
  }

  option {
    border: 0px;
  }

  option:hover {
    background-color: orange;
  }

  // input:focus,
  // select:focus {
  //   box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  // }

  label {
    margin-bottom: 6px;
  }

  input[type="date"] {
    background-color: #ffffff;

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */

      color: white;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */

      color: white;
    }

    ::-ms-input-placeholder {
      /* IE 10+ */

      color: white;
    }

    ::-moz-placeholder {
      /* Firefox 18- */

      color: white;
    }
  }

  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }
}

.action_button {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 13px !important;
  position: relative;
}

.action_button:disabled {
  background-color: rgba(69, 7, 154, 0.5) !important;
}

.action_button_edit_product {
  // background-color: var(--primary-color) !important;
  // color: var(--white) !important;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 13px !important;
  position: relative;
}

.collection_width {
  min-width: 650px;
}

.support_width {
  min-width: 450px !important;
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .collection_width {
    min-width: unset;
  }

  .support_width {
    min-width: unset;
  }
}

.blue * {
  color: blue !important;
}

.edit_legacy_user_info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(200px), 2fr));
  // grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
  // align-items: center;
  // justify-content: space-between;
  // flex-wrap: wrap;
  gap: 10px;
  font-weight: 700;

  > div {
    width: 100% !important;
  }
}

.edit_legacy_user_info_150 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(150px), 2fr));
  // grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
  // align-items: center;
  // justify-content: space-between;
  // flex-wrap: wrap;
  gap: 10px;
  font-weight: 700;

  > div {
    width: 100% !important;
  }
}

.edit_legacy_user_infoy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  > div {
    width: 32% !important;
  }
}

.edit_legacy_user_infox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  > div {
    width: 49% !important;
  }
}

.bulk_upload_nav {
  padding: 60px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .nav_items {
    width: 195px;
    height: 80px;
    padding: 30px 15px;
    box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    background-color: var(--ash);
    color: var(--white);

    * {
      color: var(--white);
    }
  }

  .active {
    background-color: var(--primary-color);
  }
}

.input_loader {
  position: absolute;
  right: 10px;
  bottom: 17px;
}
