.laoder_suspense {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.laoder_item {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
  // align-items: center;
  min-height: 450px;
  width: 100%;
}



.laoder_modal {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
  // align-items: center;
  min-height: 100px;
  width: 100%;
}


.loader_form {
  // display: flex;
  // justify-content: center;
  // padding: 30px 0px;
  // width: 100%;
}
