:root {
  --text-color: #57584e;
  --hover-blue: #197cd7;
  --pressed-blue: #2147a8;
  --disabled-blue: rgba(25, 124, 215, 0.7);
  --deeper-blue: #171832;
  --sky-blue: #26abc7;
  --white: #fff;
  --light-font: #8a92a6;
  --secondary-grey: #f7f7f9;
  --secondary-grey-text: #6f727a;
  --danger: #f32d2d;
  --success: #55bd68;
  --toastify-color-error: blue;
  --carton: #f7f7f9;
  --ash: #6c6e7f;
  --primary-color: #45079a;
  --light-purple: #f7f6fd;
  --lighter-purple: rgba(235, 233, 249, 0.6);
  --primary-color-black: #232325;
}

*,
:before,
:after {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@font-face {
  font-family: Inter-Extra-Light;
  src: url("../fonts/inter/Inter-ExtraLight.ttf");
}
@font-face {
  font-family: Inter-Light;
  src: url("../fonts/inter/Inter-Light.ttf");
}
@font-face {
  font-family: Inter-Regular;
  src: url("../fonts/inter/Inter-Regular.ttf");
}
@font-face {
  font-family: Inter-Medium;
  src: url("../fonts/inter/Inter-Medium.ttf");
}
body {
  width: 100%;
  height: auto;
  background-color: var(--light-purple);
}

*,
::before,
::after {
  padding: 0px;
  margin: 0px;
  font-family: Inter-Regular;
}

a,
Link {
  color: unset;
  cursor: pointer;
  text-decoration: none;
}

p {
  font-size: 14.75px;
  line-height: 22.5px;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 8px;
  color: var(--white);
  font-family: Inter-Regular;
}

table tr th,
table tr td {
  color: var(--primary-color-black);
}
table tr th > *,
table tr td > * {
  color: var(--primary-color-black);
}

table {
  width: 100%;
  padding: 30px;
  margin: 0px 0px;
  border-collapse: collapse;
}
table tbody tr td {
  padding: 15px 10px;
  word-break: break-all;
}
table tbody tr td h3 {
  font-size: 13px;
  margin-bottom: 7px;
  color: var(--primary-color-black);
}
table tbody tr td p {
  font-size: 13px;
  color: var(--primary-color-black);
}
table tbody tr:last-child {
  border-bottom: unset;
}
table thead {
  background-color: var(--light-purple);
}
table thead th {
  text-align: left;
  font-size: 13px;
  color: var(--secondary-grey-text) !important;
}
table th,
table td {
  padding: 10px;
  font-size: 13px;
  min-width: 100px;
}
table tbody tr:nth-child(even) {
  background-color: var(--light-purple);
}

.action_td {
  margin-left: 5px;
}
.action_td .action_td_cage {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #e0e2e7 !important;
}
.action_td .action_td_cage > * {
  margin: 0px;
}
.action_td .action_add * {
  color: var(--primary-color);
  font-size: 16px;
}
.action_td .action_edit {
  cursor: pointer;
}
.action_td .action_edit * {
  color: var(--primary-color);
  font-size: 16px;
}

.action_delete {
  cursor: pointer;
}
.action_delete * {
  color: #f32d2d !important;
}

.action_restart {
  cursor: pointer;
}
.action_restart * {
  color: #249f5d !important;
}

.status_button button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 10px;
  font-family: Inter-Regular;
}

form {
  width: 100%;
  height: auto;
}
form button {
  width: 100%;
  margin: 20px auto;
  display: block;
  text-align: center;
  border: none;
  outline: none;
  font-size: 13px;
  color: var(--primary-color);
  background-color: var(--white);
  padding: 13px 11px;
  border-radius: 6px;
  cursor: pointer;
}
form .login-button {
  margin: 35px 0px 20px 0px;
}
form button:disabled {
  background-color: var(--primary-color);
  color: #ffffff;
  font-weight: 600;
}
form input,
form textarea,
form select {
  padding: 12px;
  font-size: 13px;
  border-radius: 6px;
  outline: none;
  border: none;
  position: relative;
  width: 100%;
  border: 1px solid #eeeeee;
}
form input:focus,
form textarea:focus,
form select:focus {
  border: 1px solid var(--primary-color);
}
form option {
  border: 0px;
}
form option:hover {
  background-color: orange;
}
form label {
  margin-bottom: 6px;
}
form input[type=date] {
  background-color: #ffffff;
}
form input[type=date] ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white;
}
form input[type=date] ::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
}
form input[type=date] ::-ms-input-placeholder {
  /* IE 10+ */
  color: white;
}
form input[type=date] ::-moz-placeholder {
  /* Firefox 18- */
  color: white;
}
form ::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
form ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
form ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
form ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.action_button {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 13px !important;
  position: relative;
}

.action_button:disabled {
  background-color: rgba(69, 7, 154, 0.5) !important;
}

.action_button_edit_product {
  font-weight: 500;
  padding: 10px 20px;
  font-size: 13px !important;
  position: relative;
}

.collection_width {
  min-width: 650px;
}

.support_width {
  min-width: 450px !important;
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .collection_width {
    min-width: unset;
  }
  .support_width {
    min-width: unset;
  }
}
.blue * {
  color: blue !important;
}

.edit_legacy_user_info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
  gap: 10px;
  font-weight: 700;
}
.edit_legacy_user_info > div {
  width: 100% !important;
}

.edit_legacy_user_info_150 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 2fr));
  gap: 10px;
  font-weight: 700;
}
.edit_legacy_user_info_150 > div {
  width: 100% !important;
}

.edit_legacy_user_infoy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.edit_legacy_user_infoy > div {
  width: 32% !important;
}

.edit_legacy_user_infox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
.edit_legacy_user_infox > div {
  width: 49% !important;
}

.bulk_upload_nav {
  padding: 60px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.bulk_upload_nav .nav_items {
  width: 195px;
  height: 80px;
  padding: 30px 15px;
  box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--ash);
  color: var(--white);
}
.bulk_upload_nav .nav_items * {
  color: var(--white);
}
.bulk_upload_nav .active {
  background-color: var(--primary-color);
}

.input_loader {
  position: absolute;
  right: 10px;
  bottom: 17px;
}/*# sourceMappingURL=style.css.map */